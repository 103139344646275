export const ENV = "prod";

export const API_HOST = "https://api.letsplaymusicsite.com/v2";
export const API_NAME = "prod-api";
export const API_CONFIG = { endpoints: [{ name: API_NAME, endpoint: API_HOST }] };

export const COGNITO_CONFIG = {
  region: "us-east-1",
  userPoolId: "us-east-1_uu3VkL6Qc",
  userPoolWebClientId: "4mkam7qvulk93sodq8f51evsnf",
  authenticationFlowType: "USER_PASSWORD_AUTH"
};

export const GOOGLE_MAPS_API_KEY = "AIzaSyAxCea_rKZqaGvuw8LhwPWXLKKIdRNtfEU";
export const MAZAMA_FACEBOOK_PIXEL_ID = "801152897229088";
export const SENTRY_AUTH_TOKEN = "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";