import React, { useState, useEffect, useCallback, useRef } from "react";
import { observer } from "mobx-react";
import { debounce } from "lodash";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import { navigate } from "@reach/router";
import { ENV, GOOGLE_MAPS_API_KEY } from "../../../../config";
import ClassesStore from "../../../../stores/ClassesStore";
import ClassMapStore from "../../../../stores/ClassMapStore";
import mapOptions from "./mapOptions";
import "./ClassMap.scss";

const curriculumKeysSort = { sb: 0, lpm: 1, presto: 2 };

const containerStyle = {
  height: "100%",
  width: "100%",
  boxShadow: "var(--shadow)"
};

const ClassMap = ({ center: { lat, lng } = {} }) => {
  const [map, setMap] = useState();
  const [mapBounds, setMapBounds] = useState(null);
  const [isUserInteracting, setIsUserInteracting] = useState(false);
  const previousBoundsRef = useRef(null);

  const { lat: currentLat, lng: currentLng } = ClassMapStore?.currentCenter || {};
  const noCurrentLatLng = currentLat == null || currentLng == null;
  useEffect(() => {
    if (lat != null && lng != null && noCurrentLatLng) ClassMapStore.setCurrentCenter({ lat, lng });
  }, [lat, lng, noCurrentLatLng]);

  // Debounce the search to avoid too many API calls
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(bounds => {
      if (!bounds) return;

      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      const boundingBox = {
        north: ne.lat(),
        east: ne.lng(),
        south: sw.lat(),
        west: sw.lng()
      };

      ClassesStore.searchTeachersByMapBounds(boundingBox);
    }, 750),
    []
  );

  // Only trigger search when mapBounds changes and we're not currently interacting
  useEffect(() => {
    if (mapBounds && !isUserInteracting) {
      const boundsChanged = !previousBoundsRef.current || !mapBounds.equals(previousBoundsRef.current);

      if (boundsChanged) {
        previousBoundsRef.current = mapBounds;
        debouncedSearch(mapBounds);
      }
    }
  }, [mapBounds, isUserInteracting, debouncedSearch]);

  const { isLoaded } = useJsApiLoader({ id: "google-map-script", googleMapsApiKey: GOOGLE_MAPS_API_KEY });
  if (!isLoaded) return <div />;

  const updateCenter = () => {
    const updatedLat = map?.center?.lat();
    const updatedLng = map?.center?.lng();
    if (updatedLat != null && updatedLng != null && (updatedLat !== currentLat || updatedLng !== currentLng)) {
      ClassMapStore?.setCurrentCenter({ lat: updatedLat, lng: updatedLng });
      if (map && map.getBounds()) {
        setMapBounds(map.getBounds());
      }
    }
  };
  const updateZoom = () => {
    const updatedZoom = map?.zoom;
    if (updatedZoom && updatedZoom !== ClassMapStore?.currentZoom) {
      ClassMapStore?.setCurrentZoom(updatedZoom);

      if (map && map.getBounds()) {
        setMapBounds(map.getBounds());
      }
    }
  };
  const goToTeacherDetailPage = teacherId => () => navigate(`/teachers/${teacherId}?from=map`);

  const hasValidCenterProp = lat != null && lng != null;
  const options = {
    ...mapOptions,
    ...(hasValidCenterProp ? { center: { lat: currentLat || lat, lng: currentLng || lng } } : {}),
    zoom: ClassMapStore?.currentZoom || mapOptions?.zoom
  };

  const { searchResultsByLocation } = ClassesStore || {};
  const markers = searchResultsByLocation?.map(({ latitude, longitude, teachers }) => {
    const position = { lat: latitude, lng: longitude };

    const curriculaAtThisLocation = Object.entries(
      teachers?.reduce((acc, next) => {
        acc.sb = acc?.sb || next?.canTeachSoundBeginnings;
        acc.lpm = acc?.lpm || next?.canTeachLPM;
        acc.presto = acc?.presto || next?.canTeachPresto;
        return acc;
      }, {})
    )
      ?.filter(([_, isPresent]) => !!isPresent)
      ?.map(([curriculumKey]) => curriculumKey);
    const rawIconKey = Array.from(new Set(curriculaAtThisLocation))
      ?.sort((a, b) => (curriculumKeysSort[a] > curriculumKeysSort[b] ? 1 : -1))
      ?.join("-");
    const iconKey = rawIconKey?.match(/.+-.+-.+/) ? "all" : rawIconKey;

    // TODO: Look at how to handle the multiple teachers in one location edge case
    const teacherId = teachers?.[0]?.id;

    return (
      <Marker
        position={position}
        icon={`https://${ENV}-lpm-assets.b-cdn.net/icons/map-icons/${iconKey}.png`}
        key={latitude + "-" + longitude}
        onClick={goToTeacherDetailPage(teacherId)}
      />
    );
  });

  return (
    <div className="map-wrapper">
      <GoogleMap
        onLoad={mapInstance => {
          setMap(mapInstance);
          if (mapInstance && mapInstance.getBounds()) {
            setMapBounds(mapInstance.getBounds());
          }
        }}
        mapContainerStyle={containerStyle}
        options={options}
        onCenterChanged={updateCenter}
        onZoomChanged={updateZoom}
        onDragStart={() => setIsUserInteracting(true)}
        onDragEnd={() => {
          setIsUserInteracting(false);
          if (map && map.getBounds()) {
            setMapBounds(map.getBounds());
          }
        }}
        onZoomStart={() => setIsUserInteracting(true)}
        onIdle={() => {
          setIsUserInteracting(false);
          if (map && map.getBounds()) {
            setMapBounds(map.getBounds());
          }
        }}
      >
        {markers}
      </GoogleMap>
      <div className="map-legend">
        <div className="legend-line">
          <div className="legend-circle sb" />
          <div className="legend-label">Sound Beginnings (ages 0-4)</div>
        </div>
        <div className="legend-line">
          <div className="legend-circle lpm" />
          <div className="legend-label">Let's Play Music (ages 4-6)</div>
        </div>
        <div className="legend-line">
          <div className="legend-circle presto" />
          <div className="legend-label">Presto (ages 7-12)</div>
        </div>
      </div>
    </div>
  );
};

export default observer(ClassMap);
