import { makeAutoObservable } from "mobx";
import debounce from "debounce-promise";
import { ENV } from "../config";
import { request } from "../utils";
import CoursesStore from "./CoursesStore";
import { add, parseISO } from "date-fns";

const unique = arr => Array.from(new Set(arr));

const parseTestimonials = testimonialsString => {
  const splitIntoBlurbs = testimonialsString?.split(/\r?\n/)?.filter(s => s && !s?.match(/^(\r?\n)+$/));

  let withTrailingAttributionsCleanedUp = [];
  let current;
  for (let blurb of splitIntoBlurbs?.reverse()) {
    if (blurb?.trim()?.match(/^-/)) {
      current = blurb;
    } else {
      if (current) {
        current = blurb + " " + current;
        withTrailingAttributionsCleanedUp = withTrailingAttributionsCleanedUp.concat(current);
        current = null;
      } else {
        withTrailingAttributionsCleanedUp = withTrailingAttributionsCleanedUp.concat(blurb);
      }
    }
  }

  const withSplitAttributions = withTrailingAttributionsCleanedUp?.map(blurb => {
    const [attribution, ...rest] = blurb?.split(" -")?.reverse();
    return { attribution, text: rest?.join(" -") };
  });

  return withSplitAttributions?.reverse();
};

const augmentTeacherItem = teacher => {
  let website = teacher?.website;
  if (website && !website?.match(/^https?:\/\//)) website = "https://" + website;

  let blog = teacher?.blog;
  if (blog && !blog?.match(/^https?:\/\//)) blog = "https://" + blog;

  const now = new Date();
  return {
    ...teacher,
    profilePicture: `https://${ENV}-lpm-assets.b-cdn.net/profiles/${teacher.id}?m=${teacher.modified}`,
    classes: teacher?.classes
      ?.map(c => ({
        ...c,
        course: CoursesStore?.coursesById?.[c?.courseId],
        seatsAvailable: Math.max(0, c?.capacity - c?.stats?.studentsEnrolled || 0)
      }))
      ?.filter(c => {
        const filterOutDate = add(parseISO(c?.startDate), { weeks: 17 });
        return filterOutDate > now;
      }),
    website,
    blog,
    testimonials: teacher?.testimonials ? parseTestimonials(teacher.testimonials) : null
  };
};

// const filterOutTeachersWithNoAvailableClasses = t => t?.classes?.filter(c => c?.seatsAvailable > 0)?.length > 0;

const addPrimaryCurriculumToTeacher = teacher => {
  const curriculaCountsForThisTeacher = teacher?.classes
    ?.filter(c => c?.seatsAvailable > 0)
    ?.map(c => c?.course?.curriculum?.title)
    ?.reduce((acc, next) => {
      acc[next] = (acc[next] || 0) + 1;
      return acc;
    }, {});
  const primaryCurriculum = Object.entries(curriculaCountsForThisTeacher)?.reduce((acc, [curriculum, count]) => {
    if (count > (curriculaCountsForThisTeacher[acc] || 0)) acc = curriculum;
    return acc;
  }, "Let's Play Music");

  return { ...teacher, primaryCurriculum };
};

const sortByDistanceCloseToFar = (a, b) => a?.distance - b?.distance;

const makeLocationString = ({ location }) => {
  if (typeof location !== "object") return null;
  const { latitude, longitude } = location || {};
  if (latitude == null || longitude == null) return null;
  return `${latitude}#${longitude}`;
};

const groupTeachersByLocation = (acc, next) => {
  const teacherProfileLocationStrings = (
    next?.locations?.map(location => makeLocationString({ location })) || []
  ).filter(Boolean);

  // Removing current classes locations and just relying on location list (11/21/23)
  // const classLocationStrings = (next?.classes?.map(makeLocationString) || []).filter(Boolean);
  // const allLocationStrings = teacherProfileLocationStrings.concat(classLocationStrings);
  const locationStrings = unique(teacherProfileLocationStrings);

  for (let ls of locationStrings) {
    const teacherWithClassesForThisLocation = {
      ...next,
      classes: next?.classes?.filter(c => ls === makeLocationString(c))
    };

    acc[ls] = (acc[ls] || []).concat(teacherWithClassesForThisLocation);
  }

  return acc;
};

const ensureUniqueLocations = teachersGroupedByLocation => {
  let result = {};

  for (let [locationString, teachers] of Object.entries(teachersGroupedByLocation)) {
    if (teachers?.length === 1) {
      result[locationString] = teachers;
    } else if (teachers?.length >= 2) {
      const [origLat, origLon] = locationString?.split("#")?.map(Number);
      const angleOffset = (2 * Math.PI) / teachers?.length;
      for (let [i, teacher] of teachers.entries()) {
        const offsetLocationString = makeLocationString({
          location: {
            latitude: origLat - (0.0019 / 2) * Math.sin(i * angleOffset),
            longitude: origLon - (0.0025 / 2) * Math.cos(i * angleOffset)
          }
        });
        result[offsetLocationString] = [teacher];
      }
    }
  }

  return result;
};

class ClassesStore {
  constructor() {
    makeAutoObservable(this);
  }

  loading = false;

  rawSearchResults = [];
  rawSingleTeacherSearchResults = {};

  get searchResults() {
    return (
      this.rawSearchResults
        ?.map(augmentTeacherItem)
        // ?.filter(filterOutTeachersWithNoAvailableClasses)
        ?.map(addPrimaryCurriculumToTeacher)
        ?.sort(sortByDistanceCloseToFar)
    );
  }

  get searchResultsByLocation() {
    const groupedByLocation = this.searchResults.reduce(groupTeachersByLocation, {});
    const resultsByLocationString = ensureUniqueLocations(groupedByLocation);
    const locationSearchResults = Object.entries(resultsByLocationString).map(([locationString, teachers]) => {
      const [latitude, longitude] = locationString?.split("#").map(Number);
      return { latitude, longitude, teachers };
    });
    return locationSearchResults;
  }

  get totalClasses() {
    return (
      this.searchResults
        .map(({ classes }) => classes?.filter(c => c?.seatsAvailable > 0)?.length)
        .reduce((acc, next) => acc + next, 0) || 0
    );
  }

  get totalTeachers() {
    return this.searchResults.length || 0;
  }

  get totalLocations() {
    return this.searchResultsByLocation.length || 0;
  }

  get singleTeacherSearchResults() {
    const augmentedEntries = Object.entries(this.rawSingleTeacherSearchResults)?.map(([teacherId, teacherInfo]) => {
      const augmentedTeacherInfo = augmentTeacherItem(teacherInfo);
      const teacherInfoWithPrimaryCurriculum = addPrimaryCurriculumToTeacher(augmentedTeacherInfo);
      return [teacherId, teacherInfoWithPrimaryCurriculum];
    });

    return Object.fromEntries(augmentedEntries);
  }

  lastSearchLocation = null;
  async searchByGeo({ zipcode, latitude, longitude }) {
    const locationHasntChanged =
      this.lastSearchLocation === zipcode ||
      (latitude &&
        longitude &&
        this.lastSearchLocation?.latitude === latitude &&
        this.lastSearchLocation?.longitude === longitude);
    if (locationHasntChanged) return;

    this.loading = true;
    try {
      const zipParam = zipcode ? `zipcode=${zipcode}` : null;
      const latParam = latitude ? `latitude=${latitude}` : null;
      const lonParam = longitude ? `longitude=${longitude}` : null;
      const queryParams = [zipParam, latParam, lonParam].filter(Boolean).join("&");
      const result = await request.unauthenticated.get(`/search?${queryParams}`);
      const { latitude: lat, longitude: long, teachers: searchResults } = result || {};
      this.rawSearchResults = searchResults;
      this.loading = false;
      if (zipcode) this.lastSearchLocation = zipcode;
      if (latitude != null && longitude != null) this.lastSearchLocation = { latitude, longitude };
      return { latitude: lat, longitude: long };
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  latestSearchTerm = "";
  async rawSearchByName(searchTerm) {
    this.latestSearchTerm = searchTerm;
    if (!!searchTerm) {
      this.loading = true;
      try {
        const searchResults = await request.unauthenticated.get(`/search?name=${String(searchTerm)?.toLowerCase()}`);
        if (searchTerm === this.latestSearchTerm) {
          this.rawSearchResults = searchResults;
          this.loading = false;
          return searchResults;
        }
      } catch (err) {
        console.warn(err);
        this.loading = false;
      }
    } else {
      this.rawSearchResults = [];
    }
  }

  searchByName = debounce(search => this.rawSearchByName(search), 500, { leading: true });

  async fetchSingleTeacherSearchResult(teacherId) {
    this.loading = true;
    try {
      const teacherInfo = await request.unauthenticated.get(`/search/${teacherId}`);
      this.rawSingleTeacherSearchResults = { ...this.rawSingleTeacherSearchResults, [teacherId]: teacherInfo };
      this.loading = false;
      return teacherInfo;
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  async sendSampleClassRequest(params) {
    try {
      await request.unauthenticated.post(`/registrations/sample`, params);
      return true;
    } catch (err) {
      console.warn(err);
      return false;
    }
  }

  async searchTeachersByMapBounds(boundingBox) {
    this.loading = true;
    try {
      // Get the center point of the map
      const { north, south, east, west } = boundingBox;
      const centerLat = (north + south) / 2;
      const centerLng = (east + west) / 2;

      // Use the existing searchByGeo method
      const result = await this.searchByGeo({ latitude: centerLat, longitude: centerLng });

      this.loading = false;
      return result;
    } catch (err) {
      console.error("Error in map bounds search:", err);
      this.rawSearchResults = [];
      this.loading = false;
    }
  }

  clear() {
    this.rawSearchResults = [];
  }
}

export default new ClassesStore();
