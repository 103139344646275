import { observer } from "mobx-react";
import React, { useEffect, useRef, useCallback, useState } from "react";
import { Button, Select } from "../../-common";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import UserStore from "../../../stores/UserStore";
import "./PaymentInfoSection.scss";

export const NEW_CARD_OPTION_LABEL = "+ Add a New Card";

const PaymentInfoSection = props => {
  const {
    selectedCard,
    updateSelectedCard,
    enteringNewCard,
    setEnteringNewCard,
    actionButton,
    checkoutError,
    hasProductsToShip,
    onKeapPaymentSubmit
  } = props || {};

  const { isMobile } = useBreakpoints();
  const keapContainerRef = useRef(null);
  
  // Define states for button loading and payment success
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentMethodCreated, setPaymentMethodCreated] = useState(false);
  const [keapSessionKeyLoaded, setKeapSessionKeyLoaded] = useState(false);
  
  // Function to submit the Keap form
  const submitKeapForm = useCallback(() => {
    const keapPaymentMethod = document.querySelector("#keap-payment-method");
    if (keapPaymentMethod) {
      setIsSubmitting(true);
      keapPaymentMethod.submit();
      
      // Add event listener for the Keap response
      if (typeof onKeapPaymentSubmit === 'function') {
        window.addEventListener('message', event => {
          // Check for success property as per Keap documentation
          if (event.data && event.data.success) {
            setPaymentMethodCreated(true);
            setIsSubmitting(false);
            
            // Refetch cards to get the newly added card
            UserStore.fetchCardsForUser().then(() => {
              // After cards are fetched, pass the payment method ID to the parent
              onKeapPaymentSubmit(event.data.paymentMethodId);
              
              // Show success message briefly before closing
              setTimeout(() => {
                setEnteringNewCard(false);
              }, 1500);
            });
          } else if (event.data && !event.data.success) {
            // Handle error case
            console.error('Keap payment error:', event.data);
            setIsSubmitting(false);
          }
        }, { once: true });
      }
    }
  }, [onKeapPaymentSubmit, setEnteringNewCard]);

  // Fetch session key when entering new card mode
  useEffect(() => {
    let isMounted = true;
    
    if (enteringNewCard) {
      // Reset payment method created state
      setPaymentMethodCreated(false);
      setKeapSessionKeyLoaded(false);
      
      // Fetch session key if not already present
      if (!UserStore.keapSessionKey) {
        console.log("Fetching Keap session key...");
        UserStore.fetchKeapSessionKey()
          .then(() => {
            if (isMounted) {
              console.log("Keap session key loaded:", UserStore.keapSessionKey);
              setKeapSessionKeyLoaded(true);
            }
          })
          .catch(error => {
            console.error("Error fetching Keap session key:", error);
          });
      } else {
        setKeapSessionKeyLoaded(true);
      }
    }
    
    return () => {
      isMounted = false;
    };
  }, [enteringNewCard]);

  // Handle keap element creation and cleanup
  useEffect(() => {
    if (!enteringNewCard || !keapSessionKeyLoaded || !UserStore.keapSessionKey) {
      return;
    }
    
    // We'll use this to track if the component is still mounted
    let isMounted = true;
    
    // Capture the current ref value to use in cleanup
    const currentContainer = keapContainerRef.current;
    
    // Delay until next frame to ensure DOM is ready
    const timeoutId = requestAnimationFrame(() => {
      if (!isMounted || !currentContainer) return;
      
      try {
        // Clear any existing content
        while (currentContainer.firstChild) {
          currentContainer.removeChild(currentContainer.firstChild);
        }
        
        // Create the keap element
        const keapElement = document.createElement('keap-payment-method');
        keapElement.setAttribute('session-key', UserStore.keapSessionKey);
        keapElement.setAttribute('key', UserStore.keapSessionKey);
        keapElement.setAttribute('id', 'keap-payment-method');
        currentContainer.appendChild(keapElement);
        
        console.log("Keap element created and attached");
      } catch (error) {
        console.error("Error creating Keap element:", error);
      }
    });
    
    // Clean up function
    return () => {
      isMounted = false;
      cancelAnimationFrame(timeoutId);
      
      // Clean up the DOM using the captured reference
      if (currentContainer) {
        try {
          while (currentContainer.firstChild) {
            currentContainer.removeChild(currentContainer.firstChild);
          }
        } catch (e) {
          console.error("Error cleaning up:", e);
        }
      }
      
      // Remove any global event listeners
      const keapElement = document.getElementById('keap-payment-method');
      if (keapElement && keapElement.parentNode) {
        try {
          keapElement.parentNode.removeChild(keapElement);
        } catch (e) {
          console.error("Error removing keap element:", e);
        }
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enteringNewCard, keapSessionKeyLoaded]);

  const { rawCards, cards } = UserStore || {};
  useEffect(() => {
    if (!rawCards) UserStore?.fetchCardsForUser();
  }, [rawCards]);

  const unsetEnteringNewCard = () => setEnteringNewCard(false);

  const cardOptions = cards?.map(c => c?.label)?.concat(NEW_CARD_OPTION_LABEL);

  // Instead of rendering the keap component through JSX, we'll use a ref
  // and create the element directly in useEffect
  const cardInputs = enteringNewCard ? (
    <div className="keap-payment-wrapper" ref={keapContainerRef} style={{minHeight: "150px"}}>
      {!UserStore.keapSessionKey && (
        <div className="loading-payment-component">Loading payment form...</div>
      )}
      {paymentMethodCreated && (
        <div className="payment-method-success">
          <div className="success-icon">✓</div>
          <div className="success-message">Payment method saved successfully!</div>
        </div>
      )}
    </div>
  ) : (
    <div className="loading-payment-component">Select "Add a New Card" to enter payment details</div>
  );

  let paymentInfoContent = enteringNewCard ? (
    <div className="new-card-info-wrapper">
      <div className="input-row">
        <div className="input-wrapper">
          <div className="input-title">Card Info</div>
          {cardInputs}
        </div>
      </div>
      {(
        <div className="keap-payment-actions">
          <Button
            type="cta"
            className="keap-submit-button"
            onClick={submitKeapForm}
            disabled={isSubmitting || paymentMethodCreated || !UserStore.keapSessionKey}
          >
            {isSubmitting ? 'Processing...' : (UserStore.keapSessionKey ? 'Save Payment Method' : 'Loading...')}
          </Button>
          <div className="use-existing-card">
            <span onClick={unsetEnteringNewCard}>...or use a saved card</span>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="payment-method-select-wrapper">
      <Select
        placeholder="Select Payment Method..."
        options={cardOptions}
        value={selectedCard?.label}
        onChange={updateSelectedCard}
      />
    </div>
  );

  if (!hasProductsToShip) paymentInfoContent = <div className="no-purchase-necessary">(not necessary)</div>;

  const error = checkoutError ? (
    <div className={`checkout-error${enteringNewCard ? " new-card" : ""}`}>{checkoutError}</div>
  ) : null;

  return (
    <div className="col payment-information">
      <div className="col-header">Payment Information</div>
      {paymentInfoContent}
      {error}
      {isMobile ? null : (enteringNewCard ? null : actionButton)}
    </div>
  );
};

export default observer(PaymentInfoSection);
