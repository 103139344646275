import { navigate } from "@reach/router";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Checkbox } from "../../components/-common";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import AuthStore from "../../stores/AuthStore";
import CartStore from "../../stores/CartStore";
import MaterialsStore, {
  filterOutLateFeeProducts,
  filterOutShippingProducts,
  filterOutTaxProducts
} from "../../stores/MaterialsStore";
import UserStore from "../../stores/UserStore";
import AZTaxItem from "./AZTaxItem";
import "./Checkout.scss";
import CheckoutButton from "./CheckoutButton";
import CouponItem from "./CouponItem";
import EnrollmentItem from "./EnrollmentItem";
import LateFeeItem from "./LateFeeItem";
import PaymentInfoSection, { NEW_CARD_OPTION_LABEL } from "./PaymentInfoSection";
import ShippingItem from "./ShippingItem";
import useCardValid from "./useCardValid";

const Checkout = observer(() => {
  const { isMobile } = useBreakpoints();

  const { authDetermined, authenticated } = AuthStore || {};
  const { enrollmentsReadyForCheckout } = CartStore || {};

  const hasEnrollmentsForCheckout = !!enrollmentsReadyForCheckout?.length;
  useEffect(() => {
    if (authDetermined) {
      if (!authenticated || !hasEnrollmentsForCheckout) navigate("/");
    }
  }, [authDetermined, authenticated, hasEnrollmentsForCheckout]);

  const { rawCards, cards } = UserStore || {};
  useEffect(() => {
    if (!rawCards) UserStore?.fetchCardsForUser();
  }, [rawCards]);

  const enrollmentCourseIds = enrollmentsReadyForCheckout?.map(({ class: classObject }) => classObject?.courseId);
  useEffect(() => {
    if (enrollmentCourseIds?.length) {
      enrollmentCourseIds.forEach(courseId => MaterialsStore.fetchMaterialsForCourse(courseId));
    }
  }, [enrollmentCourseIds]);

  const [selectedCard, setSelectedCard] = useState();
  const [enteringNewCard, setEnteringNewCard] = useState(false);
  const [newCardDetails, setNewCardDetails] = useState({});
  const cardValid = useCardValid({ cardDetails: newCardDetails, setCardDetails: setNewCardDetails });
  const [processing, setProcessing] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const updateAgreeToTerms = ([newValue]) => setAgreeToTerms(newValue);
  const [keapPaymentMethodId, setKeapPaymentMethodId] = useState(null);

  // Ensure the Keap script is loaded
  useEffect(() => {
    if (!document.getElementById('keap-payment-method-script')) {
      const script = document.createElement('script');
      script.id = 'keap-payment-method-script';
      script.src = 'https://api.infusionsoft.com/payment-method/bundle/payment-method-embed.js';
      script.async = true;
      document.head.appendChild(script);
      
      console.log("Keap payment script added to the page");
    }
  }, []);

  const handleKeapPaymentSubmit = (paymentMethodId) => {
    console.log('Received payment method ID:', paymentMethodId);
    setKeapPaymentMethodId(paymentMethodId);
    
    // Find the newly added card in the refreshed cards list
    const newlyAddedCard = UserStore.cards?.find(card => card.id === paymentMethodId);
    
    if (newlyAddedCard) {
      // Use the actual card object from the API
      setSelectedCard(newlyAddedCard);
    } else {
      // Fallback to a virtual card object if not found
      const virtualCard = { 
        id: paymentMethodId, 
        isKeap: true,
        label: "New Card"
      };
      setSelectedCard(virtualCard);
    }
  };

  // Listen for Keap payment method messages
  useEffect(() => {
    const handleKeapResponse = (event) => {
      // Only process messages with Keap payment-related data
      if (event.data && typeof event.data === 'object' && ('success' in event.data)) {
        if (event.data.success) {
          console.log('Payment method created successfully:', event.data);
          setKeapPaymentMethodId(event.data.paymentMethodId);
        } else {
          console.error('Keap payment error:', event.data);
          setCheckoutError("There was an issue processing your payment. Please try again.");
        }
      }
    };

    window.addEventListener('message', handleKeapResponse);
    return () => window.removeEventListener('message', handleKeapResponse);
  }, []);

  const updateSelectedCard = selectedCardLabel => {
    if (selectedCardLabel === NEW_CARD_OPTION_LABEL) {
      setSelectedCard(NEW_CARD_OPTION_LABEL);
      setEnteringNewCard(true);
    } else {
      setSelectedCard(cards?.find(c => c?.label === selectedCardLabel));
    }
  };

  const hasProductsToShip = !!CartStore?.productItemsReadyForCheckout
    ?.filter(filterOutShippingProducts)
    ?.filter(filterOutLateFeeProducts)
    ?.filter(filterOutTaxProducts)?.length;

  let checkoutEnabled = false;
  if (!hasProductsToShip) checkoutEnabled = true;
  const hasValidCard = selectedCard === NEW_CARD_OPTION_LABEL ? cardValid : !!selectedCard;
  checkoutEnabled = hasValidCard && agreeToTerms;

  const completePurchase = async () => {
    if (processing) return;

    if (checkoutEnabled) {
      setProcessing(true);
      try {
        let card = selectedCard;

        // If using a new card, get the payment method ID from Keap
        if (selectedCard === NEW_CARD_OPTION_LABEL) {
          // Find the Keap payment element
          const keapElement = document.getElementById('keap-payment-method');

          if (keapElement) {
            // Submit the form
            keapElement.submit();

            // Wait for the payment method ID (handled by event listener)
            let attempts = 0;
            while (!keapPaymentMethodId && attempts < 10) {
              await new Promise(resolve => setTimeout(resolve, 500));
              attempts++;
            }

            if (keapPaymentMethodId) {
              card = { id: keapPaymentMethodId, isKeap: true };
            } else {
              setCheckoutError(
                "Looks like we had an issue processing your card. Please check your card details and try again."
              );
              setProcessing(false);
              return;
            }
          } else {
            setCheckoutError(
              "Payment form not found. Please try refreshing the page."
            );
            setProcessing(false);
            return;
          }
        }

        const { success, thankYouParams, error } = await CartStore?.checkOut(card);
        if (success) {
          let params;
          try {
            params = btoa(JSON.stringify(thankYouParams));
          } catch { }
          navigate(`/thankyou${params ? `?p=${params}` : ""}`);
          setTimeout(() => CartStore.clearEnrollmentsAfterCheckOut(), 100);
        } else {
          setCheckoutError(error);
        }
      } catch (err) {
        setCheckoutError("An unexpected error occurred. Please try again.");
      } finally {
        setProcessing(false);
      }
    }
  };

  const enrollmentItems = CartStore?.enrollmentsReadyForCheckout?.map(enrollment => (
    <EnrollmentItem {...enrollment} key={enrollment.id} />
  ));

  const actionButtonDisabled = !checkoutEnabled || processing;

  const actionButton = (
    <div className="checkout-wrapper">
      <div className="checkbox-row">
        <Checkbox value="I agree to the terms and conditions" selected={agreeToTerms} onChange={updateAgreeToTerms} />
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.letsplaymusicsite.com/enrollmentdisclaimer?srsltid=AfmBOooiymXH63uT7SQTJ1GmH3MCJIMlre66Q9-fOwRyJsAn-0iewMre"
          className="link"
        >
          View the terms here
        </a>
      </div>
      <CheckoutButton
        action={completePurchase}
        disabled={actionButtonDisabled || processing}
        processing={processing}
        hasProductsToShip={hasProductsToShip}
      />
      {/*   <div className="checkout-warning">
        Important: If you see an error after clicking "Complete Purchase", please check your email for a confirmation 
        before trying again. Your payment may have processed successfully despite the error message.
      </div> */}
      {/* <div className="terms-and-conditions-disclaimer">
        By completing your purch!ase, you agree to the following{" "}
        <a
          className="terms-and-conditions-link"
          href="https://www.new.letsplaymusicsite.com/enrollmentdisclaimer"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms and conditions.
        </a>
      </div> */}
      <div className="materials-reminder">
        As a reminder, your materials will be delivered to your teacher who will distribute them to you at the beginning
        of your class.
      </div>
    </div>
  );

  const paymentInfoProps = {
    selectedCard,
    updateSelectedCard,
    enteringNewCard,
    setEnteringNewCard,
    newCardDetails,
    setNewCardDetails,
    actionButton,
    checkoutError,
    hasProductsToShip,
    onKeapPaymentSubmit: handleKeapPaymentSubmit
  };
  const paymentInfoSection = <PaymentInfoSection {...paymentInfoProps} />;

  const lateFeesItem = CartStore?.lateFeeTotal ? <LateFeeItem /> : null;
  const shippingItem = hasProductsToShip ? <ShippingItem /> : null;
  const taxItem = CartStore?.AZTaxPercentage ? <AZTaxItem /> : null;

  const couponItem = CartStore?.enrollmentsReadyForCheckout?.length ? <CouponItem /> : null;

  const headerLabel = hasProductsToShip ? "What I'm Purchasing" : "What I'm Enrolling In";

  return (
    <div className="checkout">
      {isMobile ? paymentInfoSection : null}
      <div className="col">
        <div className="col-header">{headerLabel}</div>
        <div className="enrollment-items-wrapper">
          {enrollmentItems}
          {shippingItem}
          {lateFeesItem}
          {taxItem}
          {couponItem}
        </div>
      </div>
      {isMobile ? null : paymentInfoSection}
      {isMobile ? actionButton : null}
    </div>
  );
});

export default Checkout;
