import React from "react";
import "./Button.scss";

const Button = ({
  type,
  className,
  linkClassName,
  style = {},
  action = () => null,
  link,
  upperCase = true,
  children,
  disabled,
  preventDefaultAndPropagation = false,
  ...rest
}) => {
  const typeClasses = type ? " " + type : "";
  const propClasses = className ? " " + className : "";
  const classes = `lpm-button${typeClasses}${propClasses}${disabled ? " disabled" : ""}`;

  let content = children;
  if (typeof children === "string" && upperCase) {
    content = children.toUpperCase();
  }

  const handleClick = (event) => {
    if (preventDefaultAndPropagation) {
      event.preventDefault();
      event.stopPropagation();
    }
    action(event);
  };

  const button = (
    <button className={classes} style={style} onClick={handleClick} disabled={disabled} {...rest}>
      {content}
    </button>
  );
  return link ? (
    <a href={link} className={linkClassName} {...rest}>
      {button}
    </a>
  ) : (
    button
  );
};

export default Button;
